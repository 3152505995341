import produce from "immer";

const initialState = {
  accessToken: null,
  refreshToken: null,
  userId: null,
  redirect: null,
};

export default produce((draft, { type, payload }) => {
  switch (type) {
    case "app/loginResult": {
      const { access_token: accessToken, refresh_token: refreshToken } =
        payload;

      draft.accessToken = accessToken;
      draft.refreshToken = refreshToken;
      break;
    }

    case "app/refreshResult": {
      const {
        access_token: accessToken,
        refresh_token: refreshToken,
        result: id,
      } = payload;

      draft.accessToken = accessToken;
      draft.refreshToken = refreshToken;
      draft.userId = id;
      break;
    }

    case "Videos/showVideo":
    case "Video/showLogin":
      if (!draft.accessToken) {
        draft.redirect = `videos/${payload}`;
      }
      break;

    case "Purchase/showLogin":
      draft.redirect = "purchase";
      break;

    case "app/redirect":
      draft.redirect = null;
      break;

    case "app/setUser":
      const { result: id } = payload;

      draft.userId = id;
      break;

    case "Account/logout":
    case "app/logout":
      return initialState;

    default:
      break;
  }
}, initialState);
