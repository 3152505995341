import React, { PureComponent } from "react";
import { Pressable, StyleSheet } from "react-native";

import { Icon, Row, Text } from "../lib";
import { colors, p } from "../theme";

export default class Menu extends PureComponent {
  handleAccount = () => this.handleItem("account");

  handleItem = (id) => {
    const { onPressItem } = this.props;

    onPressItem(id);
  };

  render() {
    return (
      <Row.LR style={$.container}>
        <MenuItem
          id="videos"
          title="Videos"
          icon="play_arrow"
          onPress={this.handleItem}
        />
        <MenuItem
          id="account"
          icon="account_circle"
          onPress={this.handleItem}
        />
      </Row.LR>
    );
  }
}

class MenuItem extends PureComponent {
  handlePress = () => {
    const { id, onPress } = this.props;

    onPress(id);
  };

  render() {
    const { icon, title } = this.props;

    return (
      <Pressable onPress={this.handlePress} style={$.item}>
        {Boolean(title) && (
          <Text style={$.itemText}>{title.toUpperCase()}</Text>
        )}
        {Boolean(icon) && <Icon name={icon} color={colors.muted} />}
      </Pressable>
    );
  }
}

const $ = StyleSheet.create({
  container: {
    ...p[2],
    backgroundColor: colors.background,
  },
  item: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  itemText: {
    fontSize: 12,
    fontWeight: 600,
    color: colors.muted,
  },
});
