import { merge } from "rxjs";
import { mapAction, ofAction, onAction as on } from "rnna/events";
import { normalize } from "normalizr";

const events = [
  on("http/error", (services, { request, status }, { url }) => {
    const refreshTokenError =
      status === 401 &&
      request.method === "PATCH" &&
      url.pathname === "/api/user/v2/token";

    if (refreshTokenError) {
      return ofAction("app/boot");
    }

    if (status === 401) {
      return ofAction("app/logout");
    }
  }),

  on("app/boot", ({ router }) => {
    // Boot app with current window.location
    const uri = router.getLocationUri();

    router.dispatch(uri);
  }),

  on("Login/login", ({ api }, data) =>
    api.post("login", data).pipe(mapAction("app/loginResult", (p) => p))
  ),

  on(
    ["app/showAccount", "Menu/showAccount", "app/loginResult"],
    ({ router }) => {
      router.go("account");
    }
  ),

  on("Menu/showHome", ({ router }) => {
    router.go("");
  }),

  on(
    ["Videos/showLogin", "Purchase/showLogin", "Video/showLogin"],
    ({ router }) => {
      router.go("login");
    }
  ),

  on(
    ["app/refreshResult", "app/loginResult"],
    ({ api }, { access_token: token }) => {
      api.setBearer(token);
    }
  ),

  on("app/loginResult", () => {
    return ofAction("app/fetchUser");
  }),

  on("app/fetchVideos", ({ db, resources: rest }) => {
    if (db.isPremium()) {
      return rest.videos.list({ page: 1 });
    }

    // Tease
    return rest.videos.list();
  }),

  on("Videos/paginate", ({ db, resources: rest }) => {
    const page = db.getVideosPage();

    return rest.videos.list({ page });
  }),

  on("Videos/showVideo", ({ router }, id) => {
    router.go(`videos/${id}`);
  }),

  on(["Menu/showVideos", "Account/logout", "app/logout"], ({ router }) => {
    router.go("");
  }),

  on("app/fetchVideo", ({ db, resources: rest }, id) => {
    const video = db.findVideo(id);

    if (video) {
      return ofAction("app/fetchVideoModel", { result: video.id });
    }

    return rest.videos.read(id);
  }),

  on(
    ["app/fetchVideoModel", "http/setVideo"],
    ({ api, db, schemas }, { result: id }) => {
      const authenticated = db.isAuthenticated();
      const video = db.findVideo(id);
      const online = video.model.online ? 1 : 0;

      if (authenticated) {
        return api
          .search(`models/${video.model.id}/premium`, { online })
          .pipe(
            mapAction("app/setModel", (data) => normalize(data, schemas.model))
          );
      }
    }
  ),

  on("app/loginResult", ({ db, router }) => {
    const redirect = db.getRedirect();

    if (redirect) {
      return ofAction("app/redirect", redirect);
    }

    router.go("account");
  }),

  on("app/redirect", ({ router }, uri) => {
    router.go(uri);
  }),

  on("app/fetchRegions", ({ resources: rest }) => {
    return merge(rest.regions.list(), rest.plans.list({ region: 2 }));
  }),

  on("Purchase/getPlansByRegion", ({ resources: rest }, id) => {
    return rest.plans.list({ region: id });
  }),

  on("app/fetchUser", ({ api, schemas }) => {
    return api
      .get("user")
      .pipe(mapAction("app/setUser", (data) => normalize(data, schemas.user)));
  }),

  on(
    ["Videos/showPurchase", "Video/showPurchase", "Account/showPurchase"],
    ({ router }) => {
      router.go("purchase");
    }
  ),

  on("Purchase/showStates", ({ api, schemas }, countryId) => {
    return api
      .get(`countries/${countryId}/states`)
      .pipe(
        mapAction("app/setStates", (data) => normalize(data, [schemas.state]))
      );
  }),

  on("Purchase/purchase", ({ api }, data) => {
    return api.post("purchases", data).pipe(mapAction("app/showAccount"));
  }),
];

export default events;
