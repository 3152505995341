import { PureComponent } from "react";
import { Image, Pressable, StyleSheet } from "react-native";

import { Avatar, Caption, Col, Content, Icon, Row } from "../lib";
import { colors, p } from "../theme";
import Stars from "./stars";

export default class VideoItem extends PureComponent {
  handlePress = () => {
    const { id, onPress } = this.props;

    onPress(id);
  };

  render() {
    const { id, image, modelName, modelImage, modelScore, modelReviews } =
      this.props;

    return (
      <Content style={p.v3}>
        <Pressable onPress={this.handlePress} disabled={!id}>
          <Col.C>
            <Image style={$.image} source={{ uri: image }} />
            <Icon name="play_arrow" large style={$.icon} color="white" />
          </Col.C>
          <Row.LR style={$.body}>
            <Avatar name={modelName} uri={modelImage} />
            <Row.R>
              <Stars value={modelScore} />
              <Caption>({modelReviews})</Caption>
            </Row.R>
          </Row.LR>
        </Pressable>
      </Content>
    );
  }
}

const $ = StyleSheet.create({
  image: {
    backgroundColor: colors.divider,
    width: 266,
    height: 480,
  },
  icon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -24,
    marginLeft: -24,
  },
  body: {
    ...p[3],
    width: "100%",
  },
});
