import React from "react";
import { FlatList, StyleSheet } from "react-native";
import formatDistanceToNow from "date-fns/formatDistanceToNow";

import { AvatarLoader, ReviewItem, Screen, Stars } from "../components";
import {
  Avatar,
  Action,
  Caption,
  Col,
  Content,
  Loader,
  Row,
  Spinner,
  Text,
} from "../lib";
import { colors, m, p } from "../theme";

export default class Video extends Screen {
  static displayName = "Video";

  renderReviewItem = ({ item: { score, comments, created_at: createdAt } }) => (
    <ReviewItem {...{ score, comments, createdAt, style: m.b4 }} />
  );

  handleLogin = () => {
    const { dispatch, id } = this.props;

    dispatch("Video/showLogin", id);
  };

  componentDidMount() {
    this.createPlayer();
  }

  componentDidUpdate() {
    this.createPlayer();
  }

  createPlayer() {
    const { loading, premium, teasing, video } = this.props;

    if ((premium || teasing) && !loading && video && !this.player) {
      const width = Math.min(video.width || 414, 414);

      this.player = window.dacast("180460_f_" + video.file_id, "videoPlayer", {
        width,
        height: width * (video.ratio || 1),
      });
    }
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  renderTopBar() {
    return null;
  }

  renderContent() {
    const { authenticated, loading, model, premium, teasing, video } =
      this.props;

    if (!video || !model) {
      return (
        <>
          <Col.C style={$.player}>
            <Spinner large color="white" />
          </Col.C>
          <Content>
            <AvatarLoader />
          </Content>
        </>
      );
    }

    const reviews = premium ? model.reviews || [] : [];

    const content = (
      <Content>
        <Row.LR style={m.b3}>
          <Avatar name={model.name} uri={model.image.url} />
          <Caption>
            {formatDistanceToNow(new Date(video.created_at * 1000), {
              addSuffix: true,
            })}
          </Caption>
        </Row.LR>
        <Row.L style={m.b3}>
          <Text>{model.reviews_count} Reviews </Text>
          <Stars value={model.score} large />
        </Row.L>
        <FlatList
          keyExtractor={({ id }) => `review-${id}`}
          ListEmptyComponent={
            !premium && (
              <Action
                accent
                title="Purchase"
                description="Access escorts reviews with a Premium account"
                onPress={this.dispatcher("showPurchase")}
                style={[m.t4, p.v4]}
              />
            )
          }
          data={reviews}
          renderItem={this.renderReviewItem}
        />
      </Content>
    );

    if (loading) {
      return (
        <>
          <Loader dark style={$.player} />
          {content}
        </>
      );
    }

    if (!teasing && !authenticated) {
      return (
        <>
          <Action
            title="Log in"
            description="You need to log in to watch this video"
            onPress={this.dispatcher("showLogin")}
            dark
            style={$.player}
          />
          {content}
        </>
      );
    }

    if (!teasing && !premium) {
      return (
        <>
          <Action
            title="Purchase"
            description="You need a premium account to watch this video"
            onPress={this.dispatcher("showPurchase")}
            dark
            style={$.player}
          />
          {content}
        </>
      );
    }

    return (
      <>
        <Col.C nativeID="videoPlayer" style={$.player} />
        {content}
      </>
    );
  }
}

const $ = StyleSheet.create({
  player: {
    backgroundColor: colors.black,
    minHeight: 480,
  },
});
