import { concat, merge } from "rxjs";
import { switchMap } from "rxjs/operators";
import { ofAction, onBoot } from "rnna/events";
import { normalize } from "normalizr";

export default onBoot(main);

function main({ api, db, resources: rest, schemas }) {
  const token = db.getRefreshToken();

  const refresh$ = api.patch("token", { refresh_token: token }).pipe(
    switchMap((tokens) => {
      api.setBearer(tokens.access_token);

      return api.get("user").pipe(
        switchMap((user) => {
          const data = normalize(user, schemas.user);

          return concat(
            ofAction("app/refreshResult", { ...tokens, ...data }),
            ofAction("app/boot")
          );
        })
      );
    })
  );

  return merge(refresh$, rest.cities.list());
}
