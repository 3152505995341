import React from "react";
import {
  ActivityIndicator,
  Platform,
  Pressable,
  StyleSheet,
  Text,
} from "react-native";

import { colors } from "../theme";

const isAndroid = Platform.OS === "android";

function Button({
  compact = false,
  children,
  disabled = false,
  loading = false,
  onPress,
  style,
}) {
  const styles = [
    $.container,
    compact && $.compact,
    disabled && $.disabled,
    style,
  ];

  return (
    <Pressable
      {...{
        onPress,
        disabled: disabled || loading,
        style: ({ pressed }) => [styles, pressed && $.pressed],
      }}
    >
      {loading ? <ActivityIndicator color="white" /> : children}
    </Pressable>
  );
}

function BText({ compact = false, children: title, disabled = false, style }) {
  const styles = [
    $.text,
    compact && $.textCompact,
    disabled && $.textDisabled,
    style,
  ];

  return (
    <Text selectable={false} style={styles} numberOfLines={1}>
      {isAndroid ? title.toUpperCase() : title}
    </Text>
  );
}

export const Button1 = function Button1({ children: title, style, ...rest }) {
  return (
    <Button {...rest} style={[$1.container, style]}>
      <BText {...rest} style={$1.text}>
        {title}
      </BText>
    </Button>
  );
};

export const Button2 = function Button2({ children: title, style, ...rest }) {
  return (
    <Button {...rest} style={[$2.container, style]}>
      <BText {...rest} style={$2.text}>
        {title}
      </BText>
    </Button>
  );
};

const $ = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 18,
    paddingHorizontal: 24,
    borderRadius: 50,
    elevation: isAndroid ? 3 : 0,
  },
  pressed: {
    backgroundColor: colors.primary,
  },
  compact: {
    paddingVertical: 8,
    paddingHorizontal: 12,
  },
  disabled: {
    opacity: 0.75,
  },
  text: {
    textAlign: "center",
    fontSize: isAndroid ? 14 : 17,
    fontWeight: isAndroid ? "bold" : "600", // Semibold
  },
  textCompact: {
    fontSize: 13,
    fontWeight: "600", // Semibold
  },
  textDisabled: {
    // color: colors.primary,
  },
});

const $1 = StyleSheet.create({
  container: {
    backgroundColor: colors.accent,
  },
  text: {
    color: "white",
  },
});

const $2 = StyleSheet.create({
  container: {
    backgroundColor: "transparent",
    borderWidth: 1,
    borderColor: colors.divider,
  },
  text: {
    color: colors.text,
  },
});
