import React from "react";
import { Screen } from "rnna/views";

import Menu from "./menu";

export default class AppScreen extends Screen {
  handleMenu = (id) => {
    const { dispatch } = this.props;

    if (id === "account") {
      dispatch("Menu/showAccount");
    } else if (id === "videos") {
      dispatch("Menu/showVideos");
    }
  };

  renderTopBar() {
    return <Menu onPressItem={this.handleMenu} />;
  }

  renderContent() {
    return null;
  }

  renderList(topBar) {
    return null;
  }

  render() {
    return (
      <>
        {this.renderTopBar()}
        {this.renderContent()}
      </>
    );
  }
}
