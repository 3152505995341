import React from "react";
import { StyleSheet } from "react-native";

import { Screen } from "../components";
import { Button1, Caption, Col, Content, Input } from "../lib";
import { colors, m } from "../theme";

export default class Login extends Screen {
  static displayName = "Login";

  state = {
    email: "",
    password: "",
    submitted: false,
  };

  handleSubmit = () => {
    const { email, password } = this.state;

    this.setState({ submitted: true });

    this.dispatch("login", { email, password });
  };

  renderContent() {
    const { email, password, submitted } = this.state;
    const { error, errors, loading = false } = this.props;

    return (
      <Content.Form>
        <Col style={m.b3}>
          {errors.map((message) => (
            <Caption key={message} style={$.error}>
              {message}
            </Caption>
          ))}
          {submitted && error === 401 && (
            <Caption style={$.error}>Wrong email and/or password</Caption>
          )}
          <Input
            onChange={this.setter("email")}
            placeholder="Email"
            style={m.b2}
            value={email}
          />
          <Input
            onChange={this.setter("password")}
            placeholder="Password"
            secureTextEntry
            style={m.b3}
            value={password}
          />
          <Button1 {...{ loading, onPress: this.handleSubmit }}>
            Sign in
          </Button1>
        </Col>
      </Content.Form>
    );
  }
}

const $ = StyleSheet.create({
  error: {
    ...m.b3,
    textAlign: "center",
    color: colors.error.on,
  },
});
